<template>
  <div class="Expander">
    <div
      class="Expander__trigger"
      :class="open?'active':'beforeBorder'"
      @click="open=!open"
    >
      <svg
        class="Expander__trigger-Icon mx-1"
        :class="{open:open}"
        width="24"
        height="24"
        stroke="cornflowerblue"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512.000000 512.000000"
      >
        <!-- <polyline
          points="12,2 20,10 28,2"
          stroke-width="3"
          fill="none"
        /> -->
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#0A51A1"
          stroke="none"
        >
          <path
            :id="idAccordion"
            d="M2455 5106 c-83 -20 -146 -56 -211 -120 -65 -66 -105 -137 -123 -224
            -8 -37 -11 -316 -11 -902 l0 -850 -850 0 c-586 0 -865 -3 -902 -11 -86 -18
            -158 -58 -224 -123 -177 -178 -177 -455 0 -631 66 -65 138 -105 224 -124 37
            -8 307 -11 902 -11 l850 0 0 -850 c0 -586 3 -865 11 -902 18 -87 58 -158 123
            -223 179 -178 455 -178 631 -1 65 66 105 138 124 224 8 37 11 307 11 902 l0
            850 850 0 c586 0 865 3 902 11 87 18 158 58 223 123 178 179 178 454 1 632
            -66 66 -144 108 -227 124 -31 6 -390 10 -900 10 l-849 0 0 850 c0 586 -3 865
            -11 902 -18 86 -58 158 -123 224 -114 114 -267 158 -421 120z"
          />
        </g>
      </svg>
      {{ title }}
    </div>
    <transition :name="animation">
      <div
        v-show="open"
        class="Expander__body"
      >
        <!-- CONTENIDO -->
        <p
          style="margin: 0 0 20px; color: #666; font-size: 14px"
        >
          {{ content }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: 'title',
    },
    idAccordion: {
      type: String,
      default: 'idAccordion',
    },
    content: {
      type: String,
      default: 'content',
    },
  },
  data() {
    return {
      open: false,
      animation: 'bottomToTop',
    }
  },
  watch: {
    open() {
      if (this.open) {
        document.getElementById(this.idAccordion).setAttribute('d', 'M434 3050 c-206 -30 -366 -175 -419 -377 -65 -247 92 -511 350 -590 57 -17 152 -18 2195 -18 2043 0 2138 1 2195 18 258 79 415 343 350 590 -45 173 -170 305 -345 364 -53 17 -143 18 -2165 19 -1160 1 -2133 -2 -2161 -6z')
      } else {
        document.getElementById(this.idAccordion).setAttribute('d', 'M2455 5106 c-83 -20 -146 -56 -211 -120 -65 -66 -105 -137 -123 -224 -8 -37 -11 -316 -11 -902 l0 -850 -850 0 c-586 0 -865 -3 -902 -11 -86 -18 -158 -58 -224 -123 -177 -178 -177 -455 0 -631 66 -65 138 -105 224 -124 37 -8 307 -11 902 -11 l850 0 0 -850 c0 -586 3 -865 11 -902 18 -87 58 -158 123 -223 179 -178 455 -178 631 -1 65 66 105 138 124 224 8 37 11 307 11 902 l0 850 850 0 c586 0 865 3 902 11 87 18 158 58 223 123 178 179 178 454 1 632 -66 66 -144 108 -227 124 -31 6 -390 10 -900 10 l-849 0 0 850 c0 586 -3 865 -11 902 -18 86 -58 158 -123 224 -114 114 -267 158 -421 120z')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
