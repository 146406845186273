<template>
  <!-- Coming soon page-->
  <div v-scroll="handleScroll">
    <!--begin header -->
    <header
      id="header"
      class="header"
    >
      <!--begin navbar-fixed-top -->
      <nav
        class="navbar navbar-expand-lg navbar-default navbar-fixed-top navbar-efide"
        :class="{opaque: opaque}"
      >
        <!--begin container -->
        <div class="container">
          <!--begin logo -->
          <a
            class="navbar-brand"
            href="#"
          >
            <b-img
              :src="imgUrl"
              class="navbar-efide-img"
              alt="efide_logo"
            />
          </a>
          <!--end logo -->
          <!--begin navbar-toggler -->
          <button
            class="navbar-toggler navbar-efide-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            @click="showOptions"
          >
            <span class="navbar-toggler-icon navbar-efide-toggler-icon">
              <i class="fas fa-bars" />
            </span>
          </button>
          <!--end navbar-toggler -->
          <!--begin navbar-collapse -->
          <div
            id="navbarCollapse"
            class="navbar-collapse collapse"
            :class="{show: collapse}"
            style=""
          >
            <!--begin navbar-nav -->
            <ul class="navbar-nav ml-auto">
              <li class="d-flex navbar-efide_links">
                <a href="#about">
                  ¿Qué te ofrecemos?
                </a>
              </li>
              <li class="d-flex navbar-efide_links">
                <a href="#factoring">
                  Factoring en 3 simples pasos
                </a>
              </li>
              <li class="d-flex navbar-efide_links">
                <a href="#evaluation">
                  ¿Por qué pre-evaluar?
                </a>
              </li>
              <li class="d-flex navbar-efide_links">
                <a href="#advantage">
                  Ventajas con Efide Factoring
                </a>
              </li>
              <li class="discover-link navbar-efide_container-button">
                <a
                  href="/solicitud-evaluacion"
                  class="button-efide"
                >
                  ¡Pre-evalúa ahora!
                </a>
              </li>
            </ul>
            <!--end navbar-nav -->
          </div>
          <!--end navbar-collapse -->
        </div>
      <!--end container -->
      </nav>
    <!--end navbar-fixed-top -->
    </header>
    <!--begin home section -->
    <section
      id="about"
      name="about"
      class="home-section"
      style="height: 750px"
    >
      <b-img
        :src="imgBackground"
        class="landing-image-section__img"
        style="object-fit: fill; !important"
        alt="efide_logo"
      />
      <div class="home-section-overlay" />
      <!--begin container -->
      <div class="container">
        <!--begin row -->
        <div
          class="row"
          style="padding: 0 48px"
        >
          <!--begin col-md-6-->
          <div class="col-md-6" />
          <!--end col-md-6-->
          <!--begin col-md-5-->
          <div class="col-md-5 offset-md-1 card-efide">
            <h3>
              ¿Qué te ofrecemos?
            </h3>
            <div class="text-justify">
              <span class="hero-text card-efide_text">
                Nuestro servicio te permitirá <span style="color: #f15a24; font-weight: 700;"> transformar tus facturas y letras por cobrar </span> en efectivo inmediato para que mantengas el flujo de caja que necesitas.
                Sabemos que el tiempo que le dedicas a tu negocio es importante; por eso, ponemos a tu disposición nuestro equipo de ejecutivos especializados, para asesorarte y guiarte en todo el <span style="color: #f15a24; font-weight: 700;">proceso de factoring.</span>
              </span>
            </div>
            <div
              class="d-flex justify-content-center mt-2"
            >
              <a
                href="/solicitud-evaluacion"
                class="btn-red small scrool card-efide_button mx-0"
              >
                Pre-Evaluación Online
              </a>
            </div>
            <!--end popup-video-wrapper-->
          </div>
          <!--end col-sm-5-->
        </div>
        <!--end row -->
      </div>
    <!--end container -->
    </section>
    <!--begin section-white -->
    <section
      id="factoring"
      class="section-white"
    >
      <!--begin container -->
      <div class="container">
        <!--begin row -->
        <div class="row">
          <!--begin col-md-12 -->
          <div class="col-md-12 text-center margin-bottom-10">
            <h2 class="section-title mb-3">
              Factoring en 3 simples pasos
            </h2>
          </div>
          <!--end col-md-12 -->
        </div>
        <!--end row -->
      </div>
      <!--end container -->
      <!--begin services-wrapper -->
      <div class="services-wrapper">
        <!--begin container -->
        <div class="container">
          <!--begin row -->
          <div class="row justify-content-center">
            <!--begin col-md-4 -->
            <div class="col-lg-3 col-md-4 px-2">
              <div class="main-services card-efide-factoring">
                <b-img
                  :src="imgEvaluation"
                  class="icons-efide-img my-2"
                  alt="efide_logo"
                />
                <h4>
                  Pre-Evaluación Online
                </h4>
                <p>
                  Evalúa a tu cliente para ver si pre-califica para hacer factoring.
                </p>
                <div class="d-flex justify-content-center mt-2">
                  <a
                    href="/solicitud-evaluacion"
                    class="btn-red small scrool card-efide_button mx-0"
                    style="min-width: 100px"
                  >
                    Pre-Evaluación Online
                  </a>
                </div>
              </div>
            </div>
            <!--end col-md-4 -->
            <!--begin col-md-4 -->
            <div class="col-lg-3 col-md-4 px-2">
              <div class="main-services card-efide-factoring">
                <b-img
                  :src="imgAffiliation"
                  class="icons-efide-img my-2"
                  alt="efide_logo"
                />
                <h4>
                  Afiliación al Servicio
                </h4>
                <p>
                  Recepcionamos tu información de forma digital. Luego se realiza la firma de los documentos.
                </p>
                <div class="d-flex justify-content-center mt-2">
                  <a
                    href="/ingresar"
                    class="btn-red small scrool card-efide_button mx-0"
                    style="min-width: 100px"
                  >
                    Ingresa
                  </a>
                </div>
              </div>
            </div>
            <!--end col-md-4 -->
            <!--begin col-md-4 -->
            <div class="col-lg-3 col-md-4 px-2">
              <div class="main-services card-efide-factoring">
                <b-img
                  :src="imgDisbursement"
                  class="icons-efide-img my-2"
                  alt="efide_logo"
                />
                <h4>Desembolso</h4>
                <p>Confirmamos los datos de pago con tu cliente y la anotamos en Cavali.  Una vez den la conformidad procedemos a realizar el abono en menos de 48 horas "super fácil".</p>
              </div>
            </div>
            <!--end col-md-4 -->
          </div>
          <!--end row -->
        </div>
        <!--end container -->
      </div>
      <!--end services-wrapper -->
    </section>
    <!--begin section-grey -->
    <section
      id="evaluation"
      class="section-grey"
    >
      <!--begin container -->
      <div class="container">
        <!--begin row -->
        <div class="row justify-content-center">
          <!--begin col-md-12 -->
          <div class="col-md-12 text-center">
            <h3 class="section-title">
              ¿Por qué pre-evaluar?
            </h3>
            <span class="card-efide_text">En <span style="color: #f15a24; font-weight: 700;">menos de 2 minutos</span> te contamos en el siguiente video la importancia de pre-evaluar a tus clientes antes de registrarte en nuestra plataforma.</span>
          </div>
          <!--end col-md-12 -->
          <!--begin col-md-12 -->
          <div
            class="col-md-8 text-center mt-2"
            style="z-index: 2"
          >
            <!--begin iframe-youtube -->
            <div class="iframe-youtube video-container">
              <iframe
                width="860"
                height="498"
                src="https://www.youtube.com/embed/aupjKoYU66Y"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <!--end iframe-youtube -->
          </div>
          <!--end col-md-12 -->
        </div>
        <!--end row -->
      </div>
      <!--end container -->
    </section>
    <!-- begin section-white -->
    <section
      id="advantage"
      name="advantage"
      class="section-white"
    >
      <!--begin container -->
      <div class="container">
        <!--begin row -->
        <div class="row">
          <!--begin col-md-12 -->
          <div class="col-md-12 text-center margin-bottom-10">
            <h2 class="section-title mb-3">
              Ventajas de trabajar con Efide Factoring
            </h2>
          </div>
          <!--end col-md-12 -->
        </div>
        <!--end row -->
      </div>
      <!--end container -->
      <!--begin container -->
      <div class="container footer-top">
        <!--begin row -->
        <div class="row my-3">
          <!--begin col-md-4 -->
          <div class="col-md-4 text-center px-2">
            <b-img
              :src="imgRapidez"
              class="icons-efide-img--s2 my-2"
              alt="efide_logo"
            />
            <h5>
              RAPIDEZ
            </h5>
            <p>
              Puedes obtener el desembolso hasta en 24 hrs.
            </p>
          </div>
          <!--end col-md-4 -->
          <!--begin col-md-4 -->
          <div class="col-md-4 text-center px-2">
            <b-img
              :src="imgHonestidad"
              class="icons-efide-img--s2 my-2"
              alt="efide_logo"
            />
            <h5>
              HONESTIDAD
            </h5>
            <p>
              Cumplimos con todos los puntos acordados, transparencia en toda la información brindada.
            </p>
          </div>
          <!--end col-md-4 -->
          <!--begin col-md-4 -->
          <div class="col-md-4 text-center px-2">
            <b-img
              :src="imgAutonomia"
              class="icons-efide-img--s2 my-2"
              alt="efide_logo"
            />
            <h5>
              AUTONOMÍA
            </h5>
            <p>
              Tú decides cuando realizar el adelanto de la factura o letra.
            </p>
          </div>
          <!--end col-md-4 -->
        </div>
        <!--end row -->
        <!--begin row -->
        <div class="row my-3">
          <!--begin col-md-4 -->
          <div class="col-md-4 text-center px-2">
            <b-img
              :src="imgFlexibilidad"
              class="icons-efide-img--s2 my-2"
              alt="efide_logo"
            />
            <h5>FLEXIBILIDAD</h5>
            <p>Tienes la opción de realizar la firma a distancia.</p>
          </div>
          <!--end col-md-4 -->
          <!--begin col-md-4 -->
          <div class="col-md-4 text-center px-2">
            <b-img
              :src="imgProfesionalismo"
              class="icons-efide-img--s2 my-2"
              alt="efide_logo"
            />
            <h5>
              PROFESIONALISMO
            </h5>
            <p>
              Ponemos a tu disposición a nuestros ejecutivos expertos en factoring para absolver todas tus dudas.
            </p>
          </div>
          <!--end col-md-4 -->
          <!--begin col-md-4 -->
          <div class="col-md-4 text-center px-2">
            <b-img
              :src="imgCalidad"
              class="icons-efide-img--s2 my-2"
              alt="efide_logo"
            />
            <h5>
              ATENCIÓN DE CALIDAD
            </h5>
            <p>
              Nuestro equipo es empático con tus necesidades, te brindará una atención personalizada y tendrás constante asesoramiento.
            </p>
          </div>
          <!--end col-md-4 -->
        </div>
        <!--end row -->
      </div>
      <!--end container -->
    </section>
    <!-- PREGUNTAS FRECUENTES -->
    <section
      class="row justify-content-start py-5"
    >
      <div
        class="col-md-12 px-4"
      >
        <div class="col-md-12 text-center margin-bottom-10">
          <h2 class="section-title mb-3">
            Preguntas Frecuentes
          </h2>
        </div>
        <!-- eslint-disable -->
        <AccordionEfide
          idAccordion="question-1"
          title="¿Cualquier empresa puede realizar factoring?"
          content="Sí, cualquier persona natural o jurídica que, gracias a su actividad, genere documentos pagaderos a plazo (facturas, letras)"
        />
        <AccordionEfide
          idAccordion="question-2"
          title="¿Cómo se realiza la cobranza?"
          content="La gestión de cobranza se inicia el 1er día de vencimiento. Se envían correos y se realizan llamadas."
        />
        <AccordionEfide
          idAccordion="question-3"
          title="¿Genero alguna deuda con EFIDE?"
          content="El factoring no es un préstamo, por tanto no genera deuda."
        />
        <AccordionEfide
          idAccordion="question-4"
          title="¿Realmente el desembolso es rápido?"
          content="¡Si! En lo posible, nosotros realizamos el desembolso el mismo día que se realiza la toma de firmas; pero también depende que tan rápido confirme tu cliente."
        />
        <AccordionEfide
          idAccordion="question-5"
          title="¿Cuál es el monto mínimo de mi factura para poder hacer factoring?"
          content="No hay monto mínimo de facturación"
        />
        <AccordionEfide
          idAccordion="question-6"
          title="¿Qué características debe cumplir mi cliente para que califique?"
          content="Debe tener una calificación normal o CPP en la banca y además de ello, haber facturado como mínimo S/10M anuales."
        />
        <AccordionEfide
          idAccordion="question-7"
          title="¿EFIDE se encuentra regulado por la SBS?"
          content="Sí nos encontramos regulados por la SBS"
        />
        <AccordionEfide
          idAccordion="question-8"
          title="¿Con qué bancos trabajan?"
          content="Trabajamos con BCP, BBVA y SCOTIABANK. En el caso de que cuentes con una cuenta en otra entidad podemos realizar una operación interbancaria."
        />
        <AccordionEfide
          idAccordion="question-9"
          title="¿El monto a desembolsar es tal cual la simulación?"
          content="El monto a desembolsar puede variar dependiendo las detracciones y retenciones de la factura a negociar."
        />
        <AccordionEfide
          idAccordion="question-10"
          title="¿Cuál es el plazo mínimo de financiamiento?"
          content="El plazo mínimo de financiamiento es 15 días."
        />
        <AccordionEfide
          idAccordion="question-11"
          title="¿Qué sucede si mi cliente no paga?"
          content="Si tu cliente no paga (luego de la gestión exhaustiva de cobranza) recién se hace uso del pagaré que firmó junto al contrato."
        />
      </div>
    </section>
    <section
      id="aliados"
      class="row justify-content-center py-5 section-white"
    >
      <div
        class="col-8 partners-section my-3"
      >
        <p
          class="partners-section__title"
        >
          Nuestros Aliados
        </p>
        <div class="partners-section__container">
          <div class="partners-section__container-img">
            <b-img
              :src="imgAfepac"
              class="partners-section__img"
              alt="afepac_logo"
            />
          </div>
          <div class="partners-section__container-img">
            <b-img
              :src="imgCavali"
              class="partners-section__img"
              alt="cavali_logo"
            />
          </div>
          <div class="partners-section__container-img">
            <b-img
              :src="imgCCL"
              class="partners-section__img"
              alt="ccl_logo"
            />
          </div>
          <div class="partners-section__container-img">
            <b-img
              :src="imgFenacrep"
              class="partners-section__img"
              alt="fenacrep_logo"
            />
          </div>
          <div class="partners-section__container-img">
            <b-img
              :src="imgSBS"
              class="partners-section__img"
              alt="sbs_logo"
            />
          </div>
          <br>
        </div>
      </div>
    </section>
    <!--end footer -->
    <footer class="footer-widgets widget-area d-flex justify-content-center">
      <div
        class="container row"
        style="justify-content: space-evenly"
      >
        <div class="col-lg-3 col-md-4">
          <aside
            id="custom_html-2"
            class="widget_text widget widget_custom_html"
          >
            <h3 class="widget-title text-center">
              Ubícanos
            </h3>
            <div class="textwidget custom-html-widget">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31209.650499770567!2d-76.97247900000002!3d-12.098024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbe0a7851c2fd51f!2sEFIDE+-+COOPERATIVA+DE+AHORRO+Y+CR%C3%89DITO!5e0!3m2!1ses!2sus!4v1521437748830"
                width="500"
                height="200"
                frameborder="0"
                style="border:0"
                allowfullscreen=""
              />
            </div>
          </aside>
        </div>
        <div class="col-lg-3 col-md-4">
          <aside
            id="sydney_contact_info-2"
            class="widget sydney_contact_info_widget"
          >
            <h3 class="widget-title text-center">
              Contáctenos
            </h3>
            <div class="contact">
              <span>
                <b-img
                  :src="imgAddress"
                  class="sydney-svg-icon"
                  alt="linkedin-logo"
                />
              </span>
              Av. El Derby Nº 254 Oficina 1106. Santiago de Surco. Lima Central Tower
            </div>
            <div class="contact">
              <span>
                <b-img
                  :src="imgPhone"
                  class="sydney-svg-icon"
                  alt="linkedin-logo"
                />
              </span> 208-5900 | 998 330 342 | 998 388 540
            </div>
            <div class="contact">
              <span>
                <b-img
                  :src="imgMail"
                  class="sydney-svg-icon"
                  alt="linkedin-logo"
                />
              </span>
              <a href="mailto:info@efide.com.pe">info@efide.com.pe</a>
            </div>
          </aside>
        </div>
        <div class="col-lg-3 col-md-4">
          <aside
            id="nav_menu-3"
            class="widget widget_nav_menu"
          >
            <h3 class="widget-title text-center">
              Síguenos
            </h3>
            <div class="menu-social-container">
              <ul
                id="menu-social"
                class="menu"
              >
                <li
                  id="menu-item-2154"
                  class="contact"
                >
                  <b-img
                    :src="imgLinkedin"
                    class="sydney-svg-icon"
                    alt="linkedin-logo"
                  />
                  <a
                    href="https://pe.linkedin.com/company/efide-servicios-financieros"
                    target="_blank"
                  >
                    LinkedIn
                  </a>
                </li>
                <li
                  id="menu-item-1436"
                  class="contact"
                >
                  <b-img
                    :src="imgFacebook"
                    class="sydney-svg-icon"
                    alt="linkedin-logo"
                  />
                  <a
                    href="https://www.facebook.com/EfideCooperativa/"
                    target="_blank"
                  >
                    Facebook
                  </a>
                </li>
                <li
                  id="menu-item-2060"
                  class="contact"
                >
                  <b-img
                    :src="imgInstagram"
                    class="sydney-svg-icon"
                    alt="linkedin-logo"
                  />
                  <a
                    href="https://www.instagram.com/efidefactoring/"
                    target="_blank"
                  >
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BImg,
} from 'bootstrap-vue'

import AccordionEfide from '@core/components/accordion-efide/AccordionEfide.vue'

require('@/assets/css/style.css')
require('@/assets/css/animate.css')
require('@/assets/css/pe-icon-7-stroke.css')
require('@/assets/css/style-magnific-popup.css')
require('@/assets/css/all.min.css')

export default {
  directives: {
    scroll: {
      inserted(el, binding) {
        function f(evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      },
    },
  },
  components: {
    BImg,
    AccordionEfide,
  },
  data() {
    return {
      opaque: false,
      collapse: false,
      displayValue: 'none',
      imgBackground: require('@/assets/images/efide/Welcome-Efide-newgirl-left.webp'),
      imgEvaluation: require('@/assets/images/efide/evaluación.svg'),
      imgAffiliation: require('@/assets/images/efide/afiliación.svg'),
      imgDisbursement: require('@/assets/images/efide/desembolso.svg'),
      imgLinkedin: require('@/assets/images/efide/linkedin.svg'),
      imgFacebook: require('@/assets/images/efide/facebook.svg'),
      imgInstagram: require('@/assets/images/efide/instagram.svg'),
      imgAddress: require('@/assets/images/efide/address.svg'),
      imgMail: require('@/assets/images/efide/mail.svg'),
      imgPhone: require('@/assets/images/efide/phone.svg'),
      imgRapidez: require('@/assets/images/efide/rapidez.svg'),
      imgAutonomia: require('@/assets/images/efide/autonomia.svg'),
      imgFlexibilidad: require('@/assets/images/efide/ventajas-flexibilidad.jpg'),
      imgHonestidad: require('@/assets/images/efide/ventajas-honestidad.jpg'),
      imgProfesionalismo: require('@/assets/images/efide/ventajas-profesionalismo.jpg'),
      imgCalidad: require('@/assets/images/efide/ventajas-atencion-de-calidad.jpg'),
      imgAfepac: require('@/assets/images/efide/Afepac-PNG.png'),
      imgCavali: require('@/assets/images/efide/Cavali.png'),
      imgCCL: require('@/assets/images/efide/CCL.png'),
      imgFenacrep: require('@/assets/images/efide/Fenacrep.png'),
      imgSBS: require('@/assets/images/efide/SBS.png'),
    }
  },
  computed: {
    imgUrl() {
      let imgVal

      if (!this.opaque) {
        imgVal = require('@/assets/images/efide/efide_white.svg')
      } else {
        imgVal = require('@/assets/images/efide/efide_color.svg')
      }
      return imgVal
    },
    backgroundEfide() {
      return {
        background: `url(${this.imgBackground}) top center no-repeat`,
        'background-size': '100% 100%',
      }
    },
  },
  mounted() {
    const gtm = document.createElement('script')
    gtm.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PB3PRM7');"
    document.head.insertBefore(gtm, document.head.firstElementChild)
    const gtmNoScript = document.createElement('noscript')
    gtmNoScript.innerHTML = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-PB3PRM7' height='0' width='0' style='display:none;visibility:hidden'></iframe>"
    document.body.insertBefore(gtmNoScript, document.body.firstElementChild)
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 250) {
        this.opaque = true
        this.displayValue = 'block'
      } else {
        this.opaque = false
        this.displayValue = 'none'
      }
    },
    showOptions() {
      this.collapse = !this.collapse
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
